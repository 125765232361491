import React, { useEffect, useState } from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import { Grid, Stack } from "@mui/material";
// import CustomerCard from '../components/CustomerCard';
// import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
// import SplitSection from '../components/SplitSection';
// import StatsBox from '../components/StatsBox';
// import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
// import SvgCharts from '../svg/SvgCharts';
import { Helmet } from "react-helmet"
import './services.css'
import { Link } from "gatsby"
import Caraousel from '../components/Caraousel';
import Reviews from '../components/reviews';
import Discover from '../components/discover'
import cardsData from '../data/aboutwork';
import Typewriter from 'typewriter-effect';
import Blog from "../components/blog1"
import Reward from "../components/reward"


const initializeHubspotForm = () => {
  if ('hbspt' in window) { // Just to make sure the script is loaded (you can also do it by attaching an `onload` event to the script but I did it in this way
    window.hbspt.forms.create({
      portalId: "20309806",
      region: "na1",
      formId: "5bfd574f-4212-4b28-b827-7a8e0312823d",
      target: "#form-container"
    });
  } else {
    setTimeout(initializeHubspotForm, 500)
  }
}

const Index = () => {
  useEffect(() => {
    initializeHubspotForm();
  }, [])
  const [chatbot, setChatbot] = useState(false);

  useEffect(() => {
    // Check if localStorage is available (client-side)
    if (typeof window !== 'undefined') {
      const chatbotValue = localStorage.getItem("chatbot");
      setChatbot(chatbotValue === "true");
    }
  }, []);
  useEffect(() => {
    function initApollo() {
      var n = Math.random().toString(36).substring(7);
      var o = document.createElement("script");
      o.src = "https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=" + n;
      o.async = true;
      o.defer = true;
      o.onload = function () {
        window.trackingFunctions.onLoad({ appId: "66312ccf53fe5801c7e5189f" });
      };
      document.head.appendChild(o);
    }
    initApollo();
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      !function () {
        var reb2b = window.reb2b = window.reb2b || [];
        if (reb2b.invoked) return;
        reb2b.invoked = true;
        reb2b.methods = ["identify", "collect"];
        reb2b.factory = function (method) {
          return function () {
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            reb2b.push(args);
            return reb2b;
          };
        };
        for (var i = 0; i < reb2b.methods.length; i++) {
          var key = reb2b.methods[i];
          reb2b[key] = reb2b.factory(key);
        }
        reb2b.load = function (key) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
          var first = document.getElementsByTagName("script")[0];
          first.parentNode.insertBefore(script, first);
        };
        reb2b.SNIPPET_VERSION = "1.0.1";
        reb2b.load("R6G5YHZK7J65");
      }();
    `;
    document.head.appendChild(script);
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>C4Scale - Delivering Software Teams On Demand</title> */}
        <title>C4Scale - Start up and Code At Scale</title>
        <meta name="description" content="C4Scale helps startups, SMEs to build software products, services, and solutions to transform your business." />
        <meta name="title" content="AI Transformation for CTO | AI Strategy for CTO | ROI on Generative AI"/>
        <meta name="keywords" content="AI Transformation, AI Strategy, CTO, Generative AI, ROI on AI"/>
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
        {chatbot && (
          <script type="text/javascript" src="https://rohitk0107.github.io/myaibot/api/v1/test/embed-script.js?id=3aH9kF8x2sL7wP1q" id="3aH9kF8x2sL7wP1q" defer></script>
        )}

      </Helmet>
      <div class="mt-4 text-center w-full flex justify-center items-center">
        <div class="text-lg lg:text-xl font-medium highlight-text flex-col sm:flex-row" style={{ display: "flex" }}>
          <p>*<a href="/cdo-handbook-7-ways-to-cut-down-data-costs" class="text-indigo-600">Download</a> our latest EBook on</p><p>&nbsp;7 ways to reduce data costs</p>
        </div>
      </div>

      <section className="pt-20 md:pt-24">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            {/* <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            C4Scale delivers software teams on-demand !
          </h1> */}
            <div className="typewriter">
              <Typewriter
                options={{
                  strings: ['Code For Scale', 'Build | Extend | Scale, Your Products & Teams', 'Your Vision, We build it.'],
                  autoStart: true,
                  loop: true,
                }}

              /></div>
            <h1 className="text-4xl lg:text-5xl xl:text-[54px] font-[600] leading-none">
              C4Scale builds your products, @ Scale!
            </h1>
            {/* <p className="text-xl lg:text-2xl mt-6 font-light">
             We Work With You, For You !
Hire Remote Engineers from Skilled Talent Pool!
          </p> */}
            <p className="text-xl lg:text-2xl mt-6 font-light">

            </p>
            <p className="mt-8 md:mt-12">
              <Link to="#get-started"><Button size="lg">Get Started Now</Button></Link>
            </p>
            {/* <p className="mt-4 text-gray-600">*agree to our terms &amp; conditions</p> */}
          </div>
          <div className="lg:w-1/2">
            <HeroImage />
          </div>
        </div>
      </section>
      <section className="alternate-bg">
        <div style={{ margin: '0 3rem' }}>
          <div class=" mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
            <div class="max-w-3xl mx-auto text-center">
              {/* C4Scale helps startups, SMEs, enterprises to build software products, services, and solutions to transform their business. */}
              <h2 class="text-3xl font-extrabold text-gray-900">
                C4Scale helps startups and enterprises to build software products on the cloud.
              </h2>
              <p class="mt-4 text-lg text-gray-600">{/* Our experts can understand your business needs, convert them to custom tech solutions and realize your organizational goals within your budget!
Industries we work are below */}

              </p>
            </div>
            <dl class="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
              {/* <div class="relative card-hover">
        <dt>
          <svg class="h-6 w-6" style={{position: 'absolute'}} xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg>
          <p class="  text-lg leading-6 font-medium text-gray-900" style={{marginLeft: '2rem'}}>Ecommerce</p>
        </dt>
        <dd class="mt-2   text-base text-gray-600">
          30+ e-commerce solutions, 12 fintech top-funded products.
        </dd>
      </div> */}
              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>GenAI Solutions that works for you</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  We simplify Generative AI so your organization benefits from powerful AI tools. We automate business workflows across verticals like finance, industrial. We possess extensive GenAI experience.
                </dd>
              </div>

              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>Custom ChatBots, LLM Integrations</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  Powerful, cost-effective solutions need experts like us. Be it AI/LLM model finetuning, quantization, LLM integrations, Ethical & Responsible AI ChatBot applications that powers search from knowledge bases, we make generative AI work for you.
                </dd>
              </div>

              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>Scale and expand your product</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  We understand what it takes to expand your product to millions of users &amp; more. Infra, Services, Security, Compliance are known to us while we scale your existing systems
                </dd>
              </div>

              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>Build your product</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  From ideation to MVP, MVP to destination, we build it and
                  bring it to your market.
                </dd>
              </div>

              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>Platformize your existing systems</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  Accelerate growth, reduce costs, streamline operations, foster innovation by platformizing your existing systems to provide more value for your product teams. We build Data, Analytics, application platforms on the cloud.
                </dd>
              </div>

              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>Logistics, Food &amp; Restaurants</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  Order management, delivery, web/app notifications, mobile apps, payment gateway integrations and more
                </dd>
              </div>

              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>Education/Learning, Health &amp; Fitness</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  E-learning solutions,  content management, medical-applications, listing, searches etc.
                </dd>
              </div>

              <div class="relative card-hover">
                <dt>
                  <svg style={{ position: 'absolute' }} xmlns="https://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                  </svg>
                  <p class="  text-lg leading-6 font-medium text-gray-900" style={{ marginLeft: '2rem' }}>Retail, Travel &amp; Fashion</p>
                </dt>
                <dd class="mt-2   text-base text-gray-600">
                  Product development solutions to manage orders, listing, searches, reviews for your users
                </dd>
              </div>
            </dl>
            {/* <div class="mx-auto text-center py-6">
      <h2 class="text-3xl font-extrabold text-gray-900">Our Top Goals</h2>
      <ul>
        <li>Professionals that are easy to communicate</li>
        <li>Speedy development with efficiency</li>
        <li>Rapid &amp; iterative development enabling faster feedback cycles</li>
      </ul>
    </div> */}
          </div>
        </div>
      </section>
      {/* <Caraousel/>
    <Reviews/> */}
      <h2 className="text-2xl lg:text-4xl font-semibold text-center" id='discover'>
        Some of our work
      </h2>
      <div className='scroll' style={{ width: "100%" }}>
  <Grid
    container
    spacing={2}
    sx={{
      justifyContent: "center",
      alignItems: "center",
      gap: "50px",
      padding: { xs: "40px 20px", md: "60px 80px 60px 80px" },
      flexWrap: "wrap",  // Allow wrapping to achieve the desired layout
      margin: "0px",
      width: "100%"
    }}
  >
    {cardsData.map((card, index) => (
      <Grid
        item
        key={index}
        xs={12} // Take up 100% width on mobile (1x1 layout)
        md={5}  // Take up approximately 50% width on medium and larger screens
      >
        <Stack
          style={{
            background: index % 2 === 0 ? "#fbfafb" : "#e7edfb",
            padding: "0px",
            margin: "0px",
            minWidth: "100%", // Full width on smaller screens
            maxWidth: "100%"  // Ensure max width is consistent with 100% layout on mobile
          }}
        >
          <Discover {...card} />
        </Stack>
      </Grid>
    ))}
  </Grid>
</div>



      <section id="features" className='mt-10'>
        <div className="container mx-auto text-center pl-0 pr-0">
          <h2 className="text-3xl lg:text-4xl font-semibold">{/* Services that we offer */}
            What we do
          </h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3 card">
              <Card className="mb-8" height="250">
                <p className="font-semibold text-xl">Custom AI Solutions</p>
                <p className="mt-4">
                  Automate your tasks, improve bottom line efficiencies & recoup capacity for growth. We have extensive experience with customizing, training,
                  fine-tuning and deploying Chat/LLM technologies including building on GPT, LLama, Mistral & other open source LLM models.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8" height="250">
                <p className="font-semibold text-xl">Data &amp; Analytics</p>
                <p className="mt-4">
                  {/* Our experts consult, engineer &amp; */} Build a data platform enabling the organization to mine value &amp; deeper insights through the analytics chain.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3 card">
              <Card className="mb-8" height="250">
                <p className="font-semibold text-xl">Product Development</p>
                <p className="mt-4">
                  {/* We can automate your */} End-to-end product development, custom applications on the web, mobile app  &amp; maintenance
                </p>
              </Card>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3 card">
              <Card className="mb-8" height="250">
                <p className="font-semibold text-xl">Cloud Transformation</p>
                <p className="mt-4">
                  {/* We understand your concerns,  */}Unique workload demands {/* and help you migrate */} needs {/* your existing infrastructure to a */} multi-cloud environment
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8" height="200">
                <p className="font-semibold text-xl">Staff Augmentation</p>
                <p className="mt-4">
                  Get World class talent to work with your team and scale your engineering execution
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3 card">
              <Card className="mb-8" height="200">
                <p className="font-semibold text-xl">Fractional CTO</p>
                <p className="mt-4">
                  {/* Our iOS/Android developers can b */}A technology vision, strategy & execution partner that aligns with your product value proposition &amp; goals and bring it to your users {/* your needs */}.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <Reward />
      <div class="relative mt-20">
        <div class="lg:mx-auto lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div class="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
            </div>
            <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden" style={{ minHeight: '30rem' }}>
                <img class="absolute inset-0 h-full w-full object-cover" src="https://turklegal.com.tr/en/news/media/posts/65/header_011.jpg" alt="" />
                {/* <div class="absolute inset-0 bg-rose-500 mix-blend-multiply"></div>
                <div class="absolute inset-0 bg-gradient-to-t from-rose-600 via-rose-600 opacity-90"></div> */}
                <div class="relative px-8">
                </div>
              </div>
            </div>
          </div>

          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div class="pt-12 sm:pt-16 lg:pt-20">
              <h2 class="text-3xl text-gray-900 font-[600] tracking-tight sm:text-4xl">
                On a mission to extend the tech capabilities
              </h2>
              <div class="mt-6 text-gray-600 space-y-6">

                <p class="text-lg">
                  Building planet scale software systems is our passion and expertise, <strong>delivering impact &amp; value</strong>. {/* C4Scale helps startups */}{/* companies */} {/* by <strong>delivering software products on the cloud and on demand.</strong>  */}{/* We help you in all phases of software development cycle, right processes to follow,  */} {/* Founded by engineers that worked on building planet scale software systems <strong>delivering impact &amp; value</strong> */} {/* to your business and your customers */}
                </p>
                <p class="text-lg">
                  C4Scale are <strong> People First Company! </strong> {/* Our engineers are professionals that are <strong>easy to communicate</strong>, */} We are efficient in writing <strong> code that matters! </strong> {/* We focus on rapid development enabling <strong>faster feedback cycles</strong> for your product. */}
                </p>
                <p class="text-lg">
                  Our motive is to bring opportunities across the globe to top talent. We do this by tapping into engineering talent nurturing them to do high impact, meaningful work.</p>
                {/* <p class="text-lg">
                Our motive is to <strong> bring opportunities across the globe to top talent. </strong> We do this by tapping into engineering talent nurturing them to do high impact, meaningful work.
                </p> */}

                {/* <p class="text-lg">
                  We are remote teams however <strong>open &amp; transparent</strong> on all communications on paper enhancing greater knowledge sharing/documentation. The focus and flexibility that remote work offers opens up learning possibilities &amp; fosters growth.
                </p> */}
              </div>
            </div>
            {/* <div class="mt-10">
              <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
                
                  <div class="border-t-2 border-gray-100 pt-6">
                    <dt class="text-base font-medium text-gray-600">Founded</dt>
                    <dd class="text-3xl font-extrabold tracking-tight text-gray-900">2021</dd>
                  </div>
                
                  <div class="border-t-2 border-gray-100 pt-6">
                    <dt class="text-base font-medium text-gray-600">Employees</dt>
                    <dd class="text-3xl font-extrabold tracking-tight text-gray-900">5</dd>
                  </div>
                
                  <div class="border-t-2 border-gray-100 pt-6">
                    <dt class="text-base font-medium text-gray-600">Beta Users</dt>
                    <dd class="text-3xl font-extrabold tracking-tight text-gray-900">521</dd>
                  </div>
                
                  <div class="border-t-2 border-gray-100 pt-6">
                    <dt class="text-base font-medium text-gray-600">Raised</dt>
                    <dd class="text-3xl font-extrabold tracking-tight text-gray-900">$25M</dd>
                  </div>
                
              </dl>
              <div class="mt-10">
                <a href="/" class="text-base font-medium text-rose-500">
                  Learn more about how we're changing the world&nbsp;→
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our team of enthusiastic marketers will analyse and evaluate how your company stacks
            against the closest competitors
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}
      {/* <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Design And Plan Your Business Growth Steps
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Once the market analysis process is completed our staff will search for opportunities
            that are in reach
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}
      {/* <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Search For Performance Optimization
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            With all the information in place you will be presented with an action plan that your
            company needs to follow
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}
      {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
      <Blog />
      <div className="relative bg-gray-200" id="get-started">
        <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
            alt=""
          />
        </div>
        <div className="relative  mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <p className="mt-2 text-indigo-700 text-3xl font-extrabold tracking-tight sm:text-4xl">Let's Meet</p>
            <div className="mt-8">
              <div id="form-container"></div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}


export default Index;
