import {Stack, Typography,Box} from '@mui/material';
import React from 'react';

const Reward = () => (
  <>
  <Stack  gap="40px" sx={{flexDirection:{md:"row",xs:"column"},width:"100%",justifyContent:"center",alignItems:"center",mt:"60px",background:"#f7f8f9",padding:"50px"}}>
    {/* <Typography sx={{fontWeight:"600",opacity:"0.7",textTransform:"capitalize",mt:"30px"}}>
        Recent Awards
    </Typography> */}
    <Box component="img" src="https://res.cloudinary.com/durti17dk/image/upload/v1716885159/blog-assets/CIOTechOutlook-PromisingStartUp-C4Scale_w0vnru_t923zf.webp" alt="CIOTechOutlook-PromisingStartUp-C4Scale" sx={{maxHeight:"120px"}}>

    </Box>
    <Box component="img" src="https://res.cloudinary.com/durti17dk/image/upload/v1716885159/blog-assets/C4Scale_BestAward_2023_wrpnsv_nmmixe.webp" sx={{maxHeight:"120px"}} alt='C4Scale_BestAward_2023'>

    </Box>
    </Stack>
  </>
);

export default Reward;
